import GameBuildTypeEnum from "../../../Enums/GameBuildTypeEnum";
import UnityGameBuildController from "./UnityGameBuildController";

const BuildControllers = {};
BuildControllers[GameBuildTypeEnum.getValue("unity-build")] =
  UnityGameBuildController;
class GameBuidManager {
  constructor(gameBuildType) {
    this.buildController = new BuildControllers[gameBuildType]();
  }

  verifyBuild = async (buildFile) => {
    const resp = await this.buildController.verifyBuild(buildFile);

    return resp;
  };
}

export default GameBuidManager;
