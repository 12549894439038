import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import styled from "styled-components";
import { PropTypes } from "prop-types";
import { AllInputTypes } from "./InputTypes";
import InputUI from "./DefaultInputUI";
import { useFormik } from "formik";
import { Div, YellowButton } from "../Styled";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { UIElements } from "../Hooks/useAnalyticsClickEvent";
const Root = styled.div``;
const Form = styled.form`
  ${(p) => `flex-wrap:${p.flexWrap}`};
  display: flex;
  ${(p) => `gap:${p.gap}`};
  ${(p) => `flex-direction:${p.direction}`};
`;
const ButtonWrap = styled(Div)`
  ${(p) => `gap:${p.gap}`};
  display: flex;
  justify-content: ${(p) =>
    p.buttonsAlignment ? `${p.buttonsAlignment}` : "space-between"};
`;

function FormUI(
  {
    UI = InputUI,
    Inputs,
    formName = "",
    SubmitButton = YellowButton,
    CustomComponent,
    onSubmit,
    allowSubmit,
    validateOnChange = false,
    validateOnBlur = false,
    inputStyles = {
      directon: "row",
      gap: "15px",
    },
    formStyles = {},
    ClearButton = YellowButton,
    defaultValues = {},
    submitText = "Submit",
    submitDisabled = false,
    clearText = "Clear",
    onClear,
    highlightRequiredFields = false,
    buttonsAlignment,
  },
  ref
) {
  const {
    gap: formGap = "20px",
    direction: formDirection = "column",
    flexWrap = "wrap",
  } = formStyles;
  const valuesRef = useRef(null);
  const formEditedRef = useRef(false);
  const defaultValuesRef = useRef(null);
  const inputValues = {};
  const inputRefs = useRef({});
  const [errored, setErrored] = useState(null);
  const [yupValidation, setYupValidation] = useState({});
  const shouldCallOnClear = useRef(false);

  const formik = useFormik({
    initialValues: defaultValues,
    validationSchema: Yup.object().shape(yupValidation),
    validateOnChange: validateOnChange,
    validateOnBlur: validateOnBlur,
    onSubmit: (values) => {
      onSubmit(values);
      setErrored(null);
      formEditedRef.current = false;
    },
    onReset: () => {
      if (shouldCallOnClear.current) {
        onClear();
        shouldCallOnClear.current = false;
      }
    },
    enableReinitialize: true,
  });

  useImperativeHandle(ref, () => ({
    hasFormValuesChanged() {
      return formEditedRef.current;
    },
  }));

  useEffect(() => {
    valuesRef.current = formik.values;
    const validationObj = {};
    Object.keys(Inputs).forEach((inputName) => {
      let inputData = Inputs[inputName];
      let isVisible = true;

      if (inputData.dependancy) {
        isVisible = false;
        isVisible = inputData.dependancy(formik.values);
      }
      inputValues[inputName] = inputData.defaultValue || "";
      if (isVisible && inputData.validation) {
        validationObj[inputName] = inputData.validation;
      }

      if (!isVisible) {
        delete formik.values[inputName];
      }
    });

    setYupValidation(validationObj);
  }, [formik.values]);

  useEffect(() => {
    defaultValuesRef.current = defaultValues;
  }, [defaultValues]);

  useEffect(() => {
    if (inputRefs.current[errored]) {
      toast.error("Please fix all the errors");
      inputRefs.current[errored].scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "center",
      });
    }
  }, [errored]);

  return (
    <Root>
      <Form
        onSubmit={formik.handleSubmit}
        gap={formGap}
        direction={formDirection}
      >
        {Object.keys(Inputs).map((inputName) => {
          let inputData = Inputs[inputName];
          let inputType = inputData.inputType;
          let inputComponent = UI[inputType];
          let isVisible = true;

          if (inputData.dependancy) {
            isVisible = false;
            isVisible = inputData.dependancy(formik.values);
          }
          let isDisabled = false;
          if (inputData.disabled) {
            if (typeof inputData.isDisabled === "boolean") {
              isDisabled = inputData.isDisabled;
            } else if (typeof inputData.isDisabled === "function") {
              isDisabled = inputData.isDisabled(formik.values);
            }
          }
          if (!errored && formik.errors[inputName]) {
            setErrored(inputName);
          }
          let props = {
            ref: (ref) => {
              inputRefs.current[inputName] = ref;
            },
            ...inputData,
            inputStyles,
            isVisible,
            name: inputName,
            error:
              formik.errors[inputName] ||
              (formik.values[inputName] === undefined &&
              highlightRequiredFields &&
              inputData.highlightRequiredField
                ? "Required Field"
                : ""),
            value: formik.values[inputName],
            onChange: (e) => {
              formEditedRef.current = true;
              formik.handleChange(e);
            },
            defaultValue: defaultValues[inputName],
          };
          return isVisible ? (
            <div key={inputName}>{inputComponent(props)}</div>
          ) : null;
        })}

        {CustomComponent ? CustomComponent : null}

        <ButtonWrap gap={formGap} buttonsAlignment={buttonsAlignment}>
          {typeof allowSubmit === "undefined" || allowSubmit(formik.values) ? (
            <SubmitButton
              data-analytics={[
                UIElements.BUTTON,
                `${formName ? `${formName}_` : ""}${submitText}`,
              ]}
              type="submit"
              disabled={submitDisabled}
            >
              {submitText}
            </SubmitButton>
          ) : (
            <SubmitButton
              style={{
                backgroundColor: "#efeff4",
                color: "#707074",
                cursor: "initial",
              }}
              type="submit"
              disabled={submitDisabled}
            >
              {submitText}
            </SubmitButton>
          )}

          {onClear && (
            <ClearButton
              type="reset"
              onClick={() => {
                shouldCallOnClear.current = true;
                formik.resetForm();
              }}
              data-analytics={[
                UIElements.BUTTON,
                `${formName ? `${formName}_` : ""}${clearText}`,
              ]}
            >
              {clearText}
            </ClearButton>
          )}
        </ButtonWrap>
      </Form>
    </Root>
  );
}

const validation = PropTypes.shape({
  yup: PropTypes.any,
  custom: PropTypes.func,
});
const inputsShape = PropTypes.shape({
  label: PropTypes.string.isRequired,
  type: PropTypes.string,
  tooltip: PropTypes.string,
  heading: PropTypes.string,
  placeholder: PropTypes.string,
  defaultValue: PropTypes.any,
  inputValue: PropTypes.any,
  customData: PropTypes.any,
  dependancy: PropTypes.func,
  disabled: PropTypes.any,
  validation: PropTypes.objectOf(validation),
  inputType: PropTypes.oneOf(AllInputTypes),
  optionsList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.any.required,
      label: PropTypes.string.required,
    })
  ),
  width: PropTypes.string,
  loadOptions: PropTypes.func,
  showSearchInput: PropTypes.bool,
  isMultiSelect: PropTypes.bool,
  setInputValue: PropTypes.func,
});
Form.propTypes = {
  UI: PropTypes.object,
  formName: PropTypes.string,
  Inputs: PropTypes.object.isRequired,
  showSubmit: PropTypes.func,
  SubmitButton: PropTypes.element,
  CustomComponent: PropTypes.element,
  ClearButton: PropTypes.element,
  allowSubmit: PropTypes.func,
  onClear: PropTypes.func,
};

const ForwardedFormUIRef = forwardRef(FormUI);

export default ForwardedFormUIRef;
