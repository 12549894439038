import React from "react";
import styled, { keyframes } from "styled-components";

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Spinner = styled.div`
  border: ${(p) => `${p.thickness}px`} solid rgba(0, 0, 0, 0.1);
  border-top: ${(p) => `${p.thickness}px solid ${p.theme.colors[p.color]}`};
  border-radius: 50%;
  width: ${(p) => `${p.size}px`};
  height: ${(p) => `${p.size}px`};
  animation: ${rotate} 1s linear infinite;
`;

const LoadingComponent = ({
  size = 64,
  color = "themeYellow",
  thickness = 6,
}) => {
  return (
    <LoadingContainer>
      <Spinner size={size} color={color} thickness={thickness} />
    </LoadingContainer>
  );
};

export default LoadingComponent;
