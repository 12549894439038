import { Button } from "../../../Styled/Buttons";
import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { FaCheckCircle } from "react-icons/fa";
import { IoIosArrowDown } from "react-icons/io";
import { RowDiv } from "../../../Styled/LayoutUtils";
import DropDown from "./DropDown";

const DropdownSelectContainer = styled.div`
  position: relative;
  display: inline-block;
`;

const Chip = styled(Button)`
  background-color: ${({ isSelected, error, primaryColor }) =>
    error ? "#cc0621" : isSelected ? primaryColor : "#f5f5f5"};
  width: max-content;
  color: ${({ isSelected }) => (isSelected ? "#fff" : "#000")};
  padding: 8px 12px;
  border-radius: 8px;
  cursor: pointer;
  &:hover {
    color: ${({ isSelected, primaryColor }) =>
      isSelected ? "#fff" : primaryColor};
  }
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
`;

const RotatableArrow = styled(IoIosArrowDown)`
  font-size: 16px;
  transition: transform 0.3s ease;
  transform: ${({ isOpen }) => (isOpen ? "rotate(180deg)" : "rotate(0deg)")};
`;

const StyledCheckIcon = styled(FaCheckCircle)`
  font-size: 14px;
`;

const DropdownSelectInput = ({
  name = "",
  label = "",
  optionsList = [],
  value = [],
  onChange,
  customData = {},
  width = "240px",
  error = "",
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  const {
    primaryColor = `#000`,
    secondaryColor = `#f5f5f5`,
    maxWidth = "300px",
  } = customData || {};

  const handleChipClick = (e) => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  return (
    <DropdownSelectContainer ref={dropdownRef}>
      <Chip
        type="button"
        isSelected={value.length > 0}
        onClick={handleChipClick}
        primaryColor={primaryColor}
        secondaryColor={secondaryColor}
        error={error}
      >
        <RowDiv alignItems="center" gap="8px">
          {label}
          {value.length ? (
            <StyledCheckIcon />
          ) : (
            <RotatableArrow isOpen={isOpen} />
          )}
        </RowDiv>
      </Chip>
      {isOpen && (
        <DropDown
          maxWidth={maxWidth}
          label={label}
          width={width}
          name={name}
          value={[...value]}
          optionsList={[...optionsList]}
          customData={customData}
          onChange={onChange}
        />
      )}
    </DropdownSelectContainer>
  );
};

export default DropdownSelectInput;
