import { Div } from "../../../Styled";
import React, { useEffect, useRef, useState } from "react";
import InputHeading from "../InputHeading";
import { Root } from "../DefaultInputStyle";
import { NetworkManager } from "../../../Managers";
import FilePreview from "../filePreview";
import { toast } from "react-toastify";
import { UIElements } from "../../../Hooks/useAnalyticsClickEvent";
import {
  FileInput,
  ErrorText,
  Label,
  PlaceHolderText,
  PreviewImages,
  UploadButton,
  UploadFileWrap,
} from "../../FileUploaderStyle";
import LoadingComponent from "../../../Styled/LoadingComponent";

const FileUploader = ({
  name,
  width,
  value,
  heading,
  placeholder,
  onChange,
  error,
  inputStyles,
  customData,
  ref,
  isVisible,
  accept,
}) => {
  const { gap, direction } = inputStyles;
  const { multiple, fileUploadApi, maxSize } = customData;
  const drop = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const onDeleteFile = (idx) => {
    onChange({ target: { name, value: "" } });
  };
  const uploadingFile = async (file) => {
    setIsLoading(true);
    if (file.length > 0) {
      if (file[0].size > maxSize * 1024 * 1024) {
        return toast.error("File size must be less than", maxSize, " mb");
      }
      const formData = new FormData();
      formData.append("file", file[0]);
      let resp = await NetworkManager.post(fileUploadApi, formData, "", {
        headers: { "Content-Type": "multipart/form-data" },
      });
      setIsLoading(false);
      if (resp.statusCode === 200) {
        onChange({ target: { name, value: resp.data.url } });
      } else {
        toast.error("Error uploading file");
      }
    }
  };
  useEffect(() => {
    const handleDragOver = (e) => {
      e.preventDefault();
      e.stopPropagation();
    };

    const handleDrop = async (e) => {
      e.preventDefault();
      e.stopPropagation();
      const dropFiles = [...e.dataTransfer.files];

      if (dropFiles && dropFiles.length) {
        uploadingFile(dropFiles);
      }
    };

    drop.current?.addEventListener("dragover", handleDragOver);
    drop.current?.addEventListener("drop", handleDrop);

    return () => {
      drop.current?.removeEventListener("dragover", handleDragOver);
      drop.current?.removeEventListener("drop", handleDrop);
    };
  }, [drop.current]);
  useEffect(() => {
    if (!fileUploadApi) {
      throw new Error(
        "File upload path is required in customData as: fileUploadApi"
      );
    }
  }, []);

  return (
    <Root
      ref={ref}
      width={width}
      gap={gap}
      direction={direction}
      isVisible={isVisible}
    >
      {heading && <InputHeading heading={heading} error={error} />}
      <UploadFileWrap error={error} ref={drop}>
        <FileInput
          name={name}
          id={name}
          type="file"
          data-analytics={[UIElements.INPUT, name]}
          onChange={async (e) => {
            uploadingFile(e.target.files);
          }}
          accept={accept}
        />
        <Div>
          <PlaceHolderText error={error}>{placeholder}</PlaceHolderText>
          <Label htmlFor={name}>
            <UploadButton>Upload</UploadButton>
          </Label>
        </Div>
        {
          <PreviewImages>
            {isLoading ? (
              <LoadingComponent />
            ) : (
              <>
                {value && (
                  <Div>
                    <FilePreview file={value} onDeleteFile={onDeleteFile} />
                    {/* <Text>Uploaded</Text> */}
                  </Div>
                )}
              </>
            )}
          </PreviewImages>
        }
        {error && <ErrorText>{error}</ErrorText>}
      </UploadFileWrap>
    </Root>
  );
};

export default FileUploader;
