import React, { useRef, useState } from "react";
import { Root } from "../DefaultInputStyle";
import InputHeading from "../InputHeading";
import {
  FileInput,
  Label,
  PlaceHolderText,
  PreviewImages,
  UploadButton,
  UploadFileWrap,
} from "../../FileUploaderStyle";
import { UIElements } from "../../../Hooks/useAnalyticsClickEvent";
import { Div } from "../../../Styled/LayoutUtils";
import FilePreview from "../filePreview";
import GameBuidManager from "./GameBuildManager";

import GameBuildTypeEnum from "../../../Enums/GameBuildTypeEnum";
import { toast } from "react-toastify";
import NetworkManager from "../../../Managers/NetworkManager";
import { GameBuildExtensions } from "./GameBuildTypeData";
import { styled } from "styled-components";
import LoadingComponent from "../../../Styled/LoadingComponent";

const Loading = styled(Div)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
`;
const Info = styled.p`
  font-size: ${(p) => p.theme.fontSize.xl};
  color: ${(p) => p.theme.colors.red};
`;

const GameBuildUploader = ({
  name,
  width,
  value,
  heading,
  placeholder,
  onChange,
  error,
  inputStyles,
  customData,
  ref,
  isVisible,
  accept,
  link,
  linkText,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const { gap, direction } = inputStyles;
  const { fileUploadApi, buildType } = customData;
  const gameBuildManager = new GameBuidManager(
    GameBuildTypeEnum.getValue(buildType)
  );

  const deleteFile = () => {
    const gameBuildFileInput = document.getElementById(name);
    gameBuildFileInput.value = "";

    onChange({ target: { name, value: "" } });
  };

  const onBuildChange = async (buildFile) => {
    if (!buildFile) {
      return;
    }

    if (!buildFile?.name?.includes(".zip")) {
      return toast.error("Only Zip File is allowed");
    }
    setIsLoading(true);
    const buildResp = await gameBuildManager.verifyBuild(buildFile);

    if (buildResp.error) {
      deleteFile();
      setIsLoading(false);
      return toast.error(buildResp.error);
    }

    const formData = new FormData();
    formData.append("file", buildResp.data);

    let resp = await NetworkManager.post(fileUploadApi, formData, "");

    const buildUrls = resp.data;
    let buildUpload = {
      type: buildType,
      fileUrls: {},
    };

    for (let extension of GameBuildExtensions[buildType]) {
      const fileUrl = buildUrls.filter((url) => url.includes(extension))[0];
      buildUpload.fileUrls[extension.slice(1)] = fileUrl;
    }

    onChange({ target: { name, value: buildUpload } });
    setIsLoading(false);
  };
  return (
    <Root width={width} gap={gap} direction={direction} isVisible={isVisible}>
      {heading && (
        <InputHeading
          heading={heading}
          error={error}
          link={link}
          linkText={linkText}
        />
      )}
      <UploadFileWrap error={error}>
        <FileInput
          name={name}
          id={name}
          type="file"
          data-analytics={[UIElements.INPUT, name]}
          accept={accept}
          onChange={(e) => {
            onBuildChange(e.target.files[0]);
          }}
        />
        <Div>
          <PlaceHolderText error={error}>{placeholder}</PlaceHolderText>
          <Label htmlFor={name}>
            <UploadButton>Upload</UploadButton>
          </Label>
        </Div>
        {
          <PreviewImages>
            {isLoading ? (
              <Loading>
                <Info>This may take some time based on your build size!</Info>
                <LoadingComponent />
              </Loading>
            ) : (
              <>
                {value && (
                  <FilePreview file={value} onDeleteFile={deleteFile} />
                )}
              </>
            )}
          </PreviewImages>
        }
        {error && <ErrorText>{error}</ErrorText>}
      </UploadFileWrap>
    </Root>
  );
};

export default GameBuildUploader;
