import React from "react";
import styled from "styled-components";
import { RowDiv } from "../../../Styled/LayoutUtils";
import { FaCheckCircle } from "react-icons/fa";

const OptionsList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

const Image = styled.img`
  width: 20px;
  height: 20px;
  object-fit: cover;
`;

const MenuItem = styled.li`
  padding: 8px 12px;
  cursor: pointer;
  background-color: ${({ isSelected, secondaryColor }) =>
    isSelected ? secondaryColor : "inherit"};
  &:hover {
    background-color: ${({ isSelected, secondaryColor }) =>
      isSelected ? secondaryColor : "#f5f5f5"};
  }
`;

const SelectedCheckIcon = styled(FaCheckCircle)`
  color: ${({ primaryColor }) => primaryColor};
`;

const DropdownOptionsList = ({
  sortedOptions,
  value,
  primaryColor,
  secondaryColor,
  onOptionClick,
  defaultLogo,
}) => {
  const handleImageError = (event) => {
    event.target.srcset = "";
    event.target.src = defaultLogo;
  };

  return (
    <OptionsList>
      {sortedOptions.map((option) => (
        <MenuItem
          key={option.id}
          secondaryColor={secondaryColor}
          isSelected={value.some((selected) => selected.id === option.id)}
          onClick={() => onOptionClick(option)}
        >
          <RowDiv alignItems="center" gap="4px" justifyContent="space-between">
            <RowDiv alignItems="left" gap="8px">
              {option?.logo ? (
                <Image
                  src={option.logo}
                  alt={option.label + " logo"}
                  onError={handleImageError}
                />
              ) : null}
              {option.label}{" "}
            </RowDiv>
            {value.some((selected) => selected.id === option.id) && (
              <SelectedCheckIcon primaryColor={primaryColor} />
            )}
          </RowDiv>
        </MenuItem>
      ))}
    </OptionsList>
  );
};

export default DropdownOptionsList;
