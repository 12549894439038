import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import React from "react";
import { ErrorText, Root } from "./DefaultInputStyle";
import InputHeading from "./InputHeading";
import { ColDiv, Div, Text } from "../../Styled";

const CheckboxInput = ({
  name,
  label,
  placeholder,
  heading,
  error,
  value,
  onChange,
  width,
  tooltip,
  disabled,
  customStyles,
  ref,
}) => {
  let Label = label;
  return (
    <ColDiv gap="16px">
      <Root ref={ref} width={width} style={{ ...customStyles }}>
        {heading && (
          <InputHeading heading={heading} error={error} tooltip={tooltip} />
        )}
        <FormControlLabel
          label={
            !heading && tooltip ? (
              <Div data-tooltip-id="data-tip" data-tooltip-content={tooltip}>
                {typeof label == "string" ? label : <Label />}
              </Div>
            ) : (
              <Text
                fontSize={
                  customStyles?.label?.fontSize
                    ? customStyles?.label?.fontSize
                    : "md"
                }
                color={
                  customStyles?.label?.color
                    ? customStyles?.label?.color
                    : "black"
                }
              >
                {typeof label == "string" ? label : <Label />}
              </Text>
            )
          }
          control={
            <Checkbox
              disabled={disabled}
              checked={value ? value : false}
              onChange={(e) => {
                onChange({ target: { name, value: e.target.checked } });
              }}
              name={name}
              placeholder={placeholder}
            />
          }
        />
      </Root>
      {error && <ErrorText>{error}</ErrorText>}
    </ColDiv>
  );
};

export default CheckboxInput;
