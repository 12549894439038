import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { Div } from "../../../outscal-commons-frontend/Styled";
import CountrySelectLeftHelper from "./CountrySelect/CountrySelectLeftHelper";
import InputHeading from "./InputHeading";
import { ErrorText, Root } from "./DefaultInputStyle";
import { UIElements } from "../../Hooks/useAnalyticsClickEvent";

const CountrySelectAndInputRoot = styled(Div)`
  display: flex;
  position: relative;
  width: 100%;
  ${(p) => `border:1px solid rgb(0, 0, 0, 0.23)`};
  ${(p) => `border-radius: ${p.theme.borderRadius.xxxs}`};
  &:hover {
    ${(p) => `border:1px solid ${p.theme.colors.themeYellow}`};
  }
  overflow: "hidden";
`;
const NumberRoot = styled.input`
  display: flex;
  ${(p) => `border-radius: ${p.theme.borderRadius.xxxs}`};
  width: 90%;
  border: none !important;
  padding: 10px 9px;
`;

const MobileNumberInput = ({
  heading,
  value,
  name,
  onChange,
  error,
  inputStyles,
}) => {
  const { gap, direction } = inputStyles;

  const onCountrySelect = (countryCode, countryObj) => {
    onChange({
      target: {
        name,
        value: {
          country_code: countryCode,
          calling_code: countryObj.calling_code,
          currency_code: countryObj.currency_code,
          number: value?.number,
        },
      },
    });
  };

  return (
    <>
      <Root gap={gap} direction={direction}>
        {heading && <InputHeading heading={heading} error={error} />}
        <CountrySelectAndInputRoot>
          <CountrySelectLeftHelper
            value={value}
            onChange={onCountrySelect}
            helperText={value?.calling_code}
          />
          <NumberRoot
            name={name}
            type="number"
            aria-label="Mobile Number"
            value={value?.number}
            onChange={(e) => {
              onChange({
                target: {
                  name,
                  value: {
                    country_code: value?.country_code || "in",
                    calling_code: value?.calling_code || "+91",
                    number: e.target.value,
                  },
                },
              });
            }}
            data-analytics={`${UIElements.INPUT}, Number`}
            onWheel={(e) => e.target.blur()}
          ></NumberRoot>
        </CountrySelectAndInputRoot>
        {error && <ErrorText>{error}</ErrorText>}
      </Root>
    </>
  );
};

export default MobileNumberInput;
