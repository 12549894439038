import React from "react";
import Autocomplete from "@mui/material/Autocomplete";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import { ErrorText, InputErrorDiv, Root } from "./DefaultInputStyle";
import { Div } from "../../Styled/LayoutUtils";
import InputHeading from "./InputHeading";
import { UIElements } from "../../Hooks/useAnalyticsClickEvent";

const SelectInput = ({
  name,
  label,
  placeholder,
  heading,
  error,
  value,
  onChange,
  width,
  optionsList,
  loadOptions,
  tooltip,
  inputStyles = {},
  disabled,
  isVisible,
  ref,
}) => {
  const { gap, direction } = inputStyles;
  return (
    <Root
      isVisible={isVisible}
      data-analytics={[UIElements.INPUT, "SingleSelect"]}
      ref={ref}
      width={width}
      gap={gap}
      direction={direction}
    >
      {heading && (
        <InputHeading heading={heading} tooltip={tooltip} error={error} />
      )}
      <InputErrorDiv>
        <FormControl fullWidth>
          <Autocomplete
            placeholder={placeholder}
            fullWidth
            disabled={disabled}
            disablePortal
            id={name}
            options={optionsList}
            value={value || { id: "", label: "" }}
            onChange={(e, v) => {
              onChange({ target: { name: name, value: v } });
            }}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderInput={(params) => (
              <TextField
                {...params}
                label={
                  label && (
                    <>
                      {!heading && tooltip ? (
                        <Div
                          data-tooltip-id="data-tip"
                          data-tooltip-content={tooltip}
                        >
                          {label}
                        </Div>
                      ) : (
                        <>{label}</>
                      )}
                    </>
                  )
                }
              />
            )}
          />
        </FormControl>
        {error && <ErrorText>{error}</ErrorText>}
      </InputErrorDiv>
    </Root>
  );
};

export default SelectInput;
