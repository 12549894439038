import { GameBuildExtensions } from "./GameBuildTypeData";
import ZipFileManager from "./ZipFileManager";
class UnityGameBuildController {
  constructor() {
    this.allowedFileSize = 100;
    this.zipFileManager = new ZipFileManager();
  }

  #verifyBuildSize = (buildFile) => {
    const buildSize = buildFile.size / 1048576;
    if (buildSize > this.allowedFileSize) {
      return false;
    }
    return true;
  };

  #verifyBuildFiles = (buildFiles) => {
    const missingBuildFiles = [],
      requiredBuildFiles = [];

    for (let requiredFileExtension of GameBuildExtensions["unity-build"]) {
      const buildFile = buildFiles.filter((file) =>
        file.name.includes(requiredFileExtension)
      )[0];

      if (!buildFile) {
        missingBuildFiles.push(requiredFileExtension);
      } else {
        requiredBuildFiles.push(buildFile);
      }
    }

    return { missingBuildFiles, requiredBuildFiles };
  };

  verifyBuild = async (buildFile) => {
    try {
      const validBuildSize = this.#verifyBuildSize(buildFile);

      if (!validBuildSize) {
        return { error: "Game Build is too large" };
      }
      const extractedBuildFiles = await this.zipFileManager.extractZipFile(
        buildFile
      );

      const { missingBuildFiles, requiredBuildFiles } =
        this.#verifyBuildFiles(extractedBuildFiles);

      if (missingBuildFiles.length > 0) {
        return {
          error: `Not a valid Unity Build`,
        };
      }

      const buildZipFile = await this.zipFileManager.zippingFiles(
        requiredBuildFiles
      );

      return { data: buildZipFile };
    } catch (error) {
      console.log(error);
    }
  };
}

export default UnityGameBuildController;
