import { Div } from "../../Styled";
import React from "react";
import styled from "styled-components";
import { MdCancel } from "react-icons/md";

const Root = styled(Div)`
  display: flex;
  flex-direction: column;
`;

const PreviewImageWrap = styled(Div)`
  ${(p) => `background:${p.theme.colors.bgGrey}`};
  ${(p) => `border-radius:${p.theme.borderRadius.xs}`};
  width: 170px;
  height: 200px;
  position: relative;
`;

const PreviewImage = styled.img`
  height: 100%;
  width: 100%;
  object-fit: contain;
  ${(p) => `border-radius:${p.theme.borderRadius.xs}`};
`;

const IconWrap = styled(Div)`
  cursor: pointer;
  position: absolute;
  top: 5px;
  right: 5px;
  opacity: 0.7;
`;

const FileName = styled.span`
  ${(p) => `font-size:${p.theme.fontSize.sm}`};
  ${(p) => `color:${p.theme.colors.textDarkGrey}`};
`;

const PreviewImageLink = styled.a``;

function filePreview({ file, onDeleteFile }) {
  const handleImageError = (event) => {
    event.target.src = "/file-icon.png";
  };

  return (
    <Root>
      <PreviewImageWrap>
        {file && (
          <PreviewImageLink href={file} target="_blank">
            <PreviewImage
              objectFit="contain"
              src={file}
              alt="provided-file"
              onError={handleImageError}
            />
          </PreviewImageLink>
        )}
        <IconWrap onClick={() => onDeleteFile(file)}>
          <MdCancel size="20px" color="grey" />
        </IconWrap>
      </PreviewImageWrap>
      {/* <FileName>{value[file].name}</FileName> */}
    </Root>
  );
}

export default filePreview;
