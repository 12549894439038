import React from "react";
import styled from "styled-components";
import InputHeading from "./InputHeading";
import { TextareaAutosize } from "@mui/material";
import { UIElements } from "../../Hooks/useAnalyticsClickEvent";
import { ErrorText, InputErrorDiv, Root } from "./DefaultInputStyle";

const TextArea = styled(TextareaAutosize)`
  width: 97%;
  max-width: 97%;
  padding: 10px 9px;
  border-radius: 4px;
  border: 2px solid ${(p) => p.theme.colors.borderGray};
  font-size: ${(p) => p.theme.fontSize.md};
  letter-spacing: inherit;
  &:focus {
    border-color: ${(p) => p.theme.colors.themeYellow};
  }
  &:hover {
    border-color: ${(p) => p.theme.colors.themeYellow};
  }
  &:focus-visible {
    outline: 0;
  }
`;
const TextAreaInput = ({
  name,
  label,
  placeholder,
  heading,
  error,
  value,
  onChange,
  width,
  tooltip,
  inputStyles = {},
  disabled,
  isVisible,
  ref,
}) => {
  const { gap, direction, rows } = inputStyles;

  return (
    <Root
      isVisible={isVisible}
      ref={ref}
      gap={gap}
      direction={direction}
      width={width}
    >
      {heading && (
        <InputHeading heading={heading} error={error} tooltip={tooltip} />
      )}
      <InputErrorDiv>
        <TextArea
          id={name}
          minRows={rows}
          name={name}
          disabled={disabled}
          value={value !== null && value !== undefined ? value : ""}
          onChange={(e) => {
            onChange(e);
          }}
          placeholder={placeholder}
          error={error ? true : false}
          data-analytics={`${UIElements.INPUT},TextArea`}
        />
        {error && <ErrorText>{error}</ErrorText>}
      </InputErrorDiv>
    </Root>
  );
};

export default TextAreaInput;
