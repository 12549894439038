import React from "react";
import styled from "styled-components";
import TextField from "@mui/material/TextField";
import { ErrorText, InputErrorDiv, Root } from "./DefaultInputStyle";
import InputHeading from "./InputHeading";
import { Div } from "../../Styled";
import { UIElements } from "../../Hooks/useAnalyticsClickEvent";

const StyledTextField = styled(TextField)`
  .MuiInputBase-root {
    background-color: white;
  }
`;

const InputTextField = ({
  name,
  label,
  placeholder,
  heading,
  error,
  value,
  onChange,
  width,
  type,
  tooltip,
  inputStyles = {},
  disabled,
  isVisible,
  ref,
  infoText,
  link,
  linkText,
}) => {
  const { gap, direction, height } = inputStyles;
  return (
    <Root
      isVisible={isVisible}
      ref={ref}
      gap={gap}
      direction={direction}
      width={width}
    >
      {heading && (
        <InputHeading
          heading={heading}
          error={error}
          tooltip={tooltip}
          infoText={infoText}
          link={link}
          linkText={linkText}
        />
      )}
      <InputErrorDiv>
        <StyledTextField
          id={name}
          InputProps={{
            inputProps: {
              "data-analytics": `${UIElements.INPUT}, TextField`,
            },
            style: {
              height,
            },
          }}
          name={name}
          disabled={disabled}
          variant="outlined"
          label={
            label && (
              <>
                {!heading && tooltip ? (
                  <Div
                    data-tooltip-id="data-tip"
                    data-tooltip-content={tooltip}
                  >
                    {label}
                  </Div>
                ) : (
                  <>{label}</>
                )}
              </>
            )
          }
          value={value !== null && value !== undefined ? value : ""}
          onChange={(e) => {
            onChange(e);
          }}
          type={type}
          placeholder={placeholder}
          fullWidth
          onWheel={(e) => e.target.blur()}
          error={error ? true : false}
        />
        {error && <ErrorText>{error}</ErrorText>}
      </InputErrorDiv>
    </Root>
  );
};

export default InputTextField;
