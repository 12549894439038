import React from "react";
import { Root } from "./DefaultInputStyle";
import InputHeading from "./InputHeading";

const HeadingInput = ({ heading, width, tooltip }) => {
  return (
    <Root>
      <InputHeading heading={heading} tooltip={tooltip} />
    </Root>
  );
};

export default HeadingInput;
