import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import React from "react";
import { Root } from "./DefaultInputStyle";
import InputHeading from "./InputHeading";
import { Div } from "../../Styled";

const MultiSelectCheckboxInput = ({
  name,
  label,
  optionsList = [],
  heading,
  error,
  value=[],
  onChange,
  width,
  tooltip,
  disabled,
  customStyles,
  ref,
}) => {

  return (
    <Root ref={ref} width={width} style={{ ...customStyles }}>
      {heading && (
        <InputHeading heading={heading} error={error} tooltip={tooltip} />
      )}
      {optionsList.map((option) => (
        <FormControlLabel
          key={option.id}
          label={
            !heading && tooltip ? (
              <Div data-tooltip-id="data-tip" data-tooltip-content={tooltip}>
                {option.label}
              </Div>
            ) : (
              <>{option.label}</>
            )
          }
          control={
            <Checkbox
              disabled={disabled}
              checked={value ? value.includes(option.id) : false}
              onChange={(e) => {
                const checked = e.target.checked;
                const updatedValue = checked
                  ? [...value, option.id]
                  : value.filter((item) => item !== option.id);

                onChange({ target: { name, value: updatedValue } });
              }}
              name={name}
              value={value}
            />
          }
        />
      ))}
    </Root>
  );
};

export default MultiSelectCheckboxInput;
