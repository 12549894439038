import styled from "styled-components";
import { Div } from "../Styled";

export const UploadFileWrap = styled(Div)`
  ${(p) => `background-color:${p.theme.colors.white}`};
  ${(p) =>
    `border:2px dashed ${
      p.error ? p.theme.colors.muiRed : p.theme.colors.grey
    }`};
  ${(p) => `border-radius:${p.theme.borderRadius.sm}`};
  padding: 30px 0px;
  text-align: center;
  display: flex;
  flex-direction: column;
  width: 100%;
  @media screen and (max-width: 767px) {
    padding: 30px 10px;
  }
`;

export const FileInput = styled.input`
  margin: 3rem 0;
  display: none;
`;

export const Label = styled.label`
  cursor: pointer;
  ${(p) =>
    `color:${p.error ? p.theme.colors.muiRed : p.theme.colors.textDarkGrey}`};
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const UploadButton = styled(Div)`
  ${(p) => `background:${p.theme.colors.yellow}`};
  ${(p) => `color:${p.theme.colors.black}`};
  padding: 0.5rem 2rem;
  ${(p) => `font-size:${p.theme.fontSize.md}`};
  ${(p) => `border:1px solid ${p.theme.colors.borderGray}`};
  ${(p) => `border-radius:${p.theme.borderRadius.xs}`};
  cursor: pointer;
  margin: 1rem 0;
  width: max-content;
`;

export const PreviewImages = styled(Div)`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

export const PlaceHolderText = styled.span`
  ${(p) => `font-size:${p.theme.fontSize.sm}`};
  ${(p) =>
    `color:${p.error ? p.theme.colors.muiRed : p.theme.colors.textDarkGrey}`};
`;

export const ErrorText = styled.span`
  ${(p) => `font-size:${p.theme.fontSize.lg}`};
  ${(p) => `color:${p.theme.colors.muiRed}`};
`;
