import React from "react";
import { Radio as MuiRadio } from "@mui/material";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { ErrorText, Root } from "./DefaultInputStyle";
import InputHeading from "./InputHeading";
import { Div } from "../../Styled";
import styled from "styled-components";
import { UIElements } from "../../Hooks/useAnalyticsClickEvent";

const CustomRadio = styled(MuiRadio)`
  padding: 3px 9px;
  padding-right: 3px;
`;

const Radio = ({
  name,
  label,
  placeholder,
  heading,
  error,
  value,
  onChange,
  width,
  optionsList,
  loadOptions,
  tooltip,
  inputStyles,
  customData,
  disabled,
  ref,
}) => {
  const { gap, direction } = inputStyles;
  return (
    <Root ref={ref} width={width} gap={gap} direction={direction}>
      {heading && (
        <InputHeading heading={heading} tooltip={tooltip} error={error} />
      )}
      <FormControl fullWidth>
        {label && (
          <FormLabel id={name}>
            {!heading && tooltip ? (
              <Div data-tooltip-id="data-tip" data-tooltip-content={tooltip}>
                {label}
              </Div>
            ) : (
              <>{label}</>
            )}
          </FormLabel>
        )}
        <RadioGroup
          aria-disabled={disabled}
          row={customData?.styles?.direction === "row"}
          aria-labelledby={name}
          name={name}
          value={value !== undefined && value !== null ? value : ""}
          onChange={(e) => {
            onChange({ target: { name, value: e.target.value } });
          }}
        >
          {optionsList.map((item) => (
            <FormControlLabel
              value={item.id}
              control={<CustomRadio />}
              label={item.label}
              key={item.id}
              data-analytics={`${UIElements.INPUT}, ${heading}`}
            />
          ))}
        </RadioGroup>
        {error && <ErrorText>{error}</ErrorText>}
      </FormControl>
    </Root>
  );
};

export default Radio;
