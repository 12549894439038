import React, { useState } from "react";
import styled from "styled-components";
import { RiStarSFill, RiStarSLine } from "react-icons/ri";
import { Theme } from "../../Styled";

const RatingWrapper = styled.div`
  display: inline-block;
`;

const Star = styled.span`
  font-size: 20px;
  cursor: pointer;
  color: ${({ selected, theme }) =>
    selected ? theme.starSelected : theme.starUnselected};
  transition: color 0.3s;

  &:hover {
    color: ${({ theme }) => theme.starHover};
  }
`;

const RatingComponent = ({ initialRating = 1, onChange }) => {
  const [rating, setRating] = useState(initialRating);

  const handleStarClick = (selectedRating) => {
    setRating(selectedRating);
    if (onChange) {
      onChange(selectedRating);
    }
  };

  return (
    <RatingWrapper>
      {[1, 2, 3, 4, 5].map((i) => (
        <Star key={i} selected={i <= rating} onClick={() => handleStarClick(i)}>
          {i <= rating ? (
            <RiStarSFill size={28} color={Theme.colors.themeYellow} />
          ) : (
            <RiStarSLine size={28} color={Theme.colors.themeYellow} />
          )}
        </Star>
      ))}
    </RatingWrapper>
  );
};

export default RatingComponent;
