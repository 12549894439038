import React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import MenuItem from "@mui/material/MenuItem";
import { FaCheckCircle } from "react-icons/fa";
import { ErrorText, Root } from "./DefaultInputStyle";
import InputHeading from "./InputHeading";
import { Div, Theme } from "../../Styled";
import { UIElements } from "../../Hooks/useAnalyticsClickEvent";
import { calculateStringSimilarity } from "../../../outscal-commons-frontend/utils/stringOperations";

const MultiSelectInput = ({
  name,
  label,
  placeholder,
  heading,
  error,
  value,
  onChange,
  width,
  optionsList = [],
  loadOptions,
  tooltip,
  inputStyles = {},
  disabled,
  ref,
  isVisible,
  disableCloseOnSelect,
  inputValue,
  setInputValue,
}) => {
  const list = JSON.parse(JSON.stringify(optionsList));
  list.sort((a, b) => {
    const idA = String(a.id);
    const idB = String(b.id);
    return idA.localeCompare(idB);
  });
  const { gap, direction, height } = inputStyles;

  return (
    <Root
      data-analytics={[UIElements.INPUT, "MultiSelect"]}
      ref={ref}
      width={width}
      gap={gap}
      direction={direction}
      isVisible={isVisible}
    >
      {heading && (
        <InputHeading heading={heading} error={error} tooltip={tooltip} />
      )}
      <Autocomplete
        id={name}
        multiple
        disabled={disabled}
        options={list}
        inputValue={inputValue}
        disableCloseOnSelect={disableCloseOnSelect}
        renderInput={(params) => (
          <TextField
            inputProps={{ style: { height } }}
            sx={{
              ".MuiOutlinedInput-root": {
                padding: "8px",
              },
            }}
            error={error ? true : false}
            {...params}
            label={
              label && (
                <>
                  {!heading && tooltip ? (
                    <Div
                      data-tooltip-id="data-tip"
                      data-tooltip-content={tooltip}
                    >
                      {label}
                    </Div>
                  ) : (
                    <>{label}</>
                  )}
                </>
              )
            }
            onChange={(e) =>
              setInputValue ? setInputValue(e.target.value) : ""
            }
            placeholder={placeholder}
          />
        )}
        value={value || []}
        onChange={(e, v) => onChange({ target: { name, value: v } })}
        fullWidth
        isOptionEqualToValue={(option, value = {}) => option.id === value?.id}
        filterOptions={(options, { inputValue }) => {
          const inputLower = inputValue.toLowerCase();
          const filteredOptions = options.filter((option) =>
            option.label.toLowerCase().includes(inputLower)
          );

          const sortedOptions = filteredOptions
            .map((option) => ({
              label: option.label,
              id: option.id,
              similarity: calculateStringSimilarity(option.label, inputLower),
            }))
            .sort((a, b) => b.similarity - a.similarity);

          return sortedOptions;
        }}
        renderOption={(props, option, { selected }) => (
          <MenuItem
            {...props}
            key={option.id}
            value={option.id}
            sx={{ justifyContent: "space-between" }}
          >
            {option?.label}
            {selected ? (
              <FaCheckCircle style={{ color: Theme.colors.textGreen }} />
            ) : null}
          </MenuItem>
        )}
      />
      {error && <ErrorText>{error}</ErrorText>}
    </Root>
  );
};

export default MultiSelectInput;
