import React from "react";
import styled from "styled-components";

const StyledInput = styled.input`
  width: 100%;
  font-size: ${(p) => p.theme.fontSize.sm};
  padding: 8px;
  box-sizing: border-box;
  outline: none;
  border: 2px solid ${(p) => p.theme.colors.grey} !important;
  border-radius: 8px;
  &:focus {
    border-color: ${({primaryColor}) => primaryColor} !important;
  }
`;

const SearchInput = ({ showSearchInput, searchTerm, setSearchTerm, primaryColor }) => {
  const handleInputChange = (event) => {
    setSearchTerm(event.target.value);
  };

  return (
    showSearchInput && (
      <StyledInput
        type="text"
        placeholder="Search"
        value={searchTerm}
        onChange={handleInputChange}
        primaryColor={primaryColor}
      />
    )
  );
};

export default SearchInput;
