import React, { useState, useEffect, useCallback } from "react";
import DropdownHeader from "./DropdownHeader";
import styled from "styled-components";
import DropdownOptionsList from "./DropdownOptionsList";
import debounce from "lodash/debounce";
import {
  getSimilaritySortedOptions,
  sortOptionsWithInputValueOnTop,
} from "../../../utils/stringOperations";
const PAGE_SIZE = 100;

const Dropdown = styled.div`
  position: inherit;
  z-index: 2;
  top: calc(100% + 10px);
  min-width: ${(p) => p.width};
  max-width: ${(p) => p.maxWidth};
  border-radius: 8px;
  border-top: none;
  background-color: ${(p) => p.theme.colors.white};
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  @media (max-width: 768px) {
    padding: 1px;
    position: inherit;
  }
`;

const DropdownContent = styled.div`
  margin-bottom: 8px;
  height: min-content;
  max-height: 300px;
  overflow-y: scroll;
      overflow-x: hidden;

`;

function DropDown({
  name,
  optionsList,
  width,
  label,
  value,
  customData,
  onChange,
  maxWidth,
}) {
  const [searchTerm, setSearchTerm] = useState("");
  const [displayOptions, setDisplayOptions] = useState(
    optionsList.slice(0, PAGE_SIZE)
  );
  const {
    showSearchInput = false,
    isMultiSelect = true,
    primaryColor = `#000`,
    secondaryColor = `#f5f5f5`,
    defaultLogo = "",
  } = customData || {};
  const debouncedSearch = useCallback(
    debounce((searchQuery) => {
      const sortedOptions = getSimilaritySortedOptions(
        searchQuery,
        optionsList,
        value
      );
      setDisplayOptions(
        sortOptionsWithInputValueOnTop(sortedOptions, value, PAGE_SIZE)
      );
    }, 10),
    [optionsList]
  );

  useEffect(() => {
    debouncedSearch(searchTerm);
    return () => debouncedSearch.cancel();
  }, [searchTerm, debouncedSearch]);

  return (
    <Dropdown maxWidth={maxWidth} width={width}>
      <DropdownHeader
        label={label}
        showSearchInput={showSearchInput}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        primaryColor={primaryColor}
        secondaryColor={secondaryColor}
        error={""}
        clearAll={() => {
          onChange({ target: { name, value: [] } });
        }}
      />
      <DropdownContent>
        <DropdownOptionsList
          sortedOptions={displayOptions}
          value={value}
          primaryColor={primaryColor}
          secondaryColor={secondaryColor}
          defaultLogo={defaultLogo}
          onOptionClick={(option) => {
            let newValue = [];
            if (isMultiSelect) {
              const isValueSelected = value.some(
                (item) => item.id === option.id
              );

              if (isValueSelected) {
                newValue = value.filter((item) => item.id !== option.id);
              } else {
                newValue = [...value, { id: option.id, label: option.label }];
              }
            } else {
              newValue = [{ id: option.id, label: option.label }];
            }
            onChange({
              target: {
                name,
                value: newValue,
              },
            });
          }}
        />
      </DropdownContent>
    </Dropdown>
  );
}

export default DropDown;
