import React from "react";
import styled from "styled-components";
import { ColDiv, RowDiv } from "../../../Styled/LayoutUtils";
import { Text } from "../../../Styled/TextStyles";
import SearchInput from "./SearchInput";
import { ErrorText } from "../DefaultInputStyle";

const StyledDropdownHeader = styled(ColDiv)`
  margin: 8px;
  border-radius: ${(p) => p.theme.borderRadius.xxs};
  padding: 8px;
  gap: 8px;
  background-color: ${(p) => p.theme.colors.backgroundGrey};
`;

const ClearAllButton = styled(Text)`
  cursor: pointer;
  padding: 4px 8px;
  border-radius: 4px;
  border: 1px solid ${({ primaryColor }) => primaryColor};
  background-color: ${({ secondaryColor }) => secondaryColor};
  &:hover {
    color: ${({ primaryColor }) => primaryColor};
    background-color: ${(p) => p.theme.colors.white};
  }
`;

const DropdownHeader = ({
  label,
  showSearchInput,
  searchTerm,
  setSearchTerm,
  primaryColor,
  secondaryColor,
  error,
  clearAll,
}) => {
  const handleClearAll = () => {
    setSearchTerm("");
    if (clearAll) {
      clearAll();
    }
  };

  return (
    <StyledDropdownHeader>
      <RowDiv justifyContent="space-between" alignItems="center">
        <Text fontSize="md" fontWeight="medium">
          {label}
        </Text>
        <ClearAllButton
          primaryColor={primaryColor}
          secondaryColor={secondaryColor}
          onClick={handleClearAll}
          fontSize="sm"
          fontWeight="medium"
        >
          Clear All
        </ClearAllButton>
      </RowDiv>
      {error && <ErrorText>{error}</ErrorText>}
      <SearchInput
        showSearchInput={showSearchInput}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        primaryColor={primaryColor}
      />
    </StyledDropdownHeader>
  );
};

export default DropdownHeader;
