import JSZip from "jszip";

class ZipFileManager {
  constructor() {
    this.zip = new JSZip();
  }

  #getZipFiles = async (zipArchive) => {
    const extractedFiles = [];
    const zipPaths = Object.keys(zipArchive.files);

    for (const relativePath of zipPaths) {
      const zipEntry = zipArchive.files[relativePath];
      if (!zipEntry.dir) {
        extractedFiles.push(zipEntry);
      }
    }

    return extractedFiles;
  };

  extractZipFile = async (zipFile) => {
    try {
      const zipArchive = await this.zip.loadAsync(zipFile);

      const extractedFiles = await this.#getZipFiles(zipArchive, "");

      return extractedFiles;
    } catch (error) {
      console.log(error);
    }
  };

  zippingFiles = async (files) => {
    try {
      const newZip = new JSZip();
      for (let file of files) {
        newZip.file(file.name, file.async("blob"));
      }

      const newZipFile = await newZip.generateAsync({ type: "blob" });

      return newZipFile;
    } catch (error) {
      console.log(error);
    }
  };
}

export default ZipFileManager;
